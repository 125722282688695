.table-scroll {
  position: relative;
  width:100%;
  z-index: 1;
  overflow: auto;
}
.table-scroll table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.table-scroll thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.table-scroll td:first-child,
.table-scroll th:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 5;
}
.table-scroll th:first-child {
  background-color: white;
}
th.contractor-years {
  max-width: 330px;
  min-width: 330px;
}
td.contractor-years {
  vertical-align: top;
}
